import { parseFixed } from '@ethersproject/bignumber'
import { BigNumber, BigNumberish, FixedNumber } from 'ethers'

import { Formatter } from './formatter'
import { TFixedNumberish } from './types'
import { checkValidAddress } from './utils/address'

export class Token {
  public readonly chainId: number
  public readonly decimals: number
  public readonly symbol?: string
  public readonly name?: string

  /**
   * The contract address on the chain on which this token lives
   */
  public readonly address: string

  public constructor(chainId: number, decimals: number, address?: string, symbol?: string, name?: string) {
    if (!Number.isSafeInteger(chainId)) {
      throw 'Invalid chainId'
    }

    const decimalsCondition = decimals >= 0 && decimals <= 18 && Number.isInteger(decimals)
    if (!decimalsCondition) {
      throw 'Invalid decimals'
    }

    this.chainId = chainId
    this.decimals = decimals
    this.symbol = symbol
    this.name = name
    if (address) this.address = checkValidAddress(address)
  }

  public equals(other: Token): boolean {
    return this.chainId === other.chainId && this.address.toLowerCase() === other.address.toLowerCase()
  }

  public toFracAmount(rawAmount: BigNumberish): FixedNumber {
    const { decimals } = this

    return FixedNumber.fromValue(BigNumber.from(rawAmount), decimals)
  }

  public toRawAmount(fracAmount: TFixedNumberish): BigNumber {
    return parseFixed(fracAmount.toString(), this.decimals)
  }

  public toUnsafeNumber(rawAmount: BigNumberish): number {
    const fracAmount = this.toFracAmount(rawAmount)
    return fracAmount.toUnsafeFloat()
  }

  public formatter(rawAmount: BigNumberish): Formatter {
    return new Formatter(this.toFracAmount(rawAmount).toString(), { suffix: this.symbol })
  }
}
