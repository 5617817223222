import { Token } from '../token'
import { SupportedChainId } from './chain'

export const SubRon = new Token(
  SupportedChainId.RoninMainnet,
  18,
  '0xe514d9deb7966c8be0ca922de8a064264ea6bcd4',
  'RON',
  'RON',
)

export const SubWRon = new Token(
  SupportedChainId.RoninMainnet,
  18,
  '0xe514d9deb7966c8be0ca922de8a064264ea6bcd4',
  'WRON',
  'Wrapped RON',
)

// TODO: Define more token
export const SubGwei = new Token(SupportedChainId.RoninMainnet, 9, '0xe514d9deb7966c8be0ca922de8a064264ea6bcd4')

export const SubWei = new Token(SupportedChainId.RoninMainnet, 18, '0xe514d9deb7966c8be0ca922de8a064264ea6bcd4')
