import RoninLogo from 'src/config/assets/token_ron.svg'
import {
  EnvironmentSettings,
  ETHEREUM_LAYER,
  ETHEREUM_NETWORK,
  FEATURES,
  NetworkConfig,
  WALLETS,
} from 'src/config/networks/network.d'

const baseConfig: EnvironmentSettings = {
  clientGatewayUrl: 'https://saigon-gnonis-cgw.roninchain.com/v1',
  txServiceUrl: 'https://saigon-gnonis-txs.roninchain.com',
  safeUrl: 'https://saigon-multisig.roninchain.com/',
  gasPrice: 1e9,
  rpcServiceUrl: 'https://saigon-testnet.roninchain.com/rpc',
  safeAppsRpcServiceUrl: 'https://saigon-testnet.roninchain.com/rpc',
  networkExplorerName: 'Saigon Explorer',
  networkExplorerUrl: 'https://saigon-app.roninchain.com',
  networkExplorerApiUrl: 'https://explorer-kintsugi.roninchain.com/v2/2021/contract',
  exchangeRatePriceUrl: 'https://exchange-rate.skymavis.com/',
  iconUrl: 'https://assets.axieinfinity.com/explorer/images/contract-icon',
}

const config: NetworkConfig = {
  environment: {
    dev: {
      ...baseConfig,
    },
    staging: {
      ...baseConfig,
    },
    production: {
      ...baseConfig,
    },
  },
  network: {
    id: ETHEREUM_NETWORK.SAIGON,
    backgroundColor: '#1c94f4',
    textColor: '#ffffff',
    label: 'Saigon Testnet',
    isTestNet: true,
    ethereumLayer: ETHEREUM_LAYER.L2,
    nativeCoin: {
      address: '0x0000000000000000000000000000000000000000',
      name: 'Ronin',
      symbol: 'RON',
      decimals: 18,
      logoUri: RoninLogo,
    },
  },
  disabledWallets: [
    WALLETS.TREZOR,
    WALLETS.LEDGER,
    WALLETS.COINBASE,
    WALLETS.FORTMATIC,
    WALLETS.OPERA,
    WALLETS.OPERA_TOUCH,
    WALLETS.PORTIS,
    WALLETS.TORUS,
    WALLETS.TRUST,
    WALLETS.WALLET_LINK,
    WALLETS.AUTHEREUM,
    WALLETS.LATTICE,
    WALLETS.KEYSTONE,
    WALLETS.WALLET_CONNECT,
    WALLETS.METAMASK,
  ],
  disabledFeatures: [FEATURES.DOMAIN_LOOKUP, FEATURES.SPENDING_LIMIT],
}

export default config
