import { TAmount, TCompactThreshold, TCustomNumberFormatOptions, TFormatOption, TOptionsFormatter } from './types'
import { isValidAmount } from './utils/formatter'

// Todo: remove this when publish new package
export class Formatter {
  public readonly amount: TAmount
  public readonly options: TOptionsFormatter

  public constructor(amount?: TAmount, options: TOptionsFormatter = { fallback: '' }) {
    this.amount = amount
    this.options = options
  }

  protected parseNumberAmount() {
    let typedValue: number

    if (typeof this.amount === 'string') {
      typedValue = parseFloat(this.amount)
    } else {
      typedValue = this.amount as number
    }

    if (!isValidAmount(typedValue)) {
      throw '[Formatter.parseNumberAmount]: NaN'
    }
    return typedValue
  }

  protected wrapFormattedResult(input = '', options?: TFormatOption): string {
    if (!input || !options) return input

    const { showPrefix, showSuffix } = options
    return `${showPrefix && this.options.prefix ? this.options.prefix : ''}${input}${
      showSuffix && this.options.suffix ? this.options.suffix : ''
    }`
  }

  protected wrapIntlNumberFormat(options?: TCustomNumberFormatOptions): string {
    const { showPrefix, showSuffix, ...intlOption } = options ?? {}

    const num = this.parseNumberAmount()

    return this.wrapFormattedResult(new Intl.NumberFormat('en-US', intlOption).format(num ?? 0), {
      showPrefix,
      showSuffix,
    })
  }

  public toExact(options?: TFormatOption): string {
    if (!isValidAmount(this.amount)) {
      return this.wrapFormattedResult(this.options.fallback ?? '', options)
    }

    /* Explanation
        - The \B keeps the regex from putting a comma at the beginning of the string.
        - (?<!\.\d*) is a negative lookbehind that says the match can't be preceded by a "." followed by zero or more digits.
        - The negative lookbehind is faster than the split and join solution
    */
    const formattedExactAmount = ((this.amount || this.options.fallback) ?? '')
      .toString()
      .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
      .replace(/^([\d,]+)$|^([\d,]+)\.0*$|^([\d,]+\.[1-9]*?)0*$/, '$1$2$3')

    return this.wrapFormattedResult(formattedExactAmount, options)
  }

  public toAbbreviations(threshold?: TCompactThreshold, options?: TCustomNumberFormatOptions): string {
    if (!isValidAmount(this.amount)) {
      return this.wrapFormattedResult(this.options.fallback ?? '', options)
    }

    const num = this.parseNumberAmount()

    if (threshold && num < threshold)
      return this.wrapIntlNumberFormat({
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        ...options,
      })

    return this.wrapIntlNumberFormat({ ...options, notation: 'compact' })
  }

  public toInteger(options?: TFormatOption): string {
    if (!isValidAmount(this.amount)) {
      return this.wrapFormattedResult(this.options.fallback ?? '', options)
    }

    return this.wrapIntlNumberFormat({
      ...options,
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  }

  public toFloat(options?: TFormatOption): string {
    if (!isValidAmount(this.amount)) {
      return this.wrapFormattedResult(this.options.fallback, options)
    }

    const num = this.parseNumberAmount()

    let minFraction = 2,
      maxFraction = 2

    if (num === 0) {
      minFraction = 0
      maxFraction = 0
    }

    if (num > 0 && num < 0.0001) {
      return `< 0.0001`
    }

    if (num > 0 && num < 0.01) {
      minFraction = 4
      maxFraction = 4
    }

    return this.wrapIntlNumberFormat({
      ...options,
      minimumFractionDigits: minFraction,
      maximumFractionDigits: maxFraction,
    })
  }

  public toCommonFormat(options?: TCustomNumberFormatOptions): string {
    if (!isValidAmount(this.amount)) {
      return this.wrapFormattedResult(this.options.fallback, options)
    }

    let maxFraction: number | undefined
    let minFraction: number | undefined
    let maxSignificant: number | undefined

    const { maximumFractionDigits, minimumFractionDigits, maximumSignificantDigits, ...restOptions } = options || {
      maximumFractionDigits: undefined,
      minimumFractionDigits: undefined,
      maximumSignificantDigits: undefined,
    }
    const useDefaultConfig = !maximumFractionDigits && !minimumFractionDigits && !maximumSignificantDigits

    const num = this.parseNumberAmount()

    if (useDefaultConfig) {
      if (num >= 1) {
        maxFraction = Math.max(4 - Math.floor(Math.log10(num)), 0)
        maxSignificant = undefined
        minFraction = undefined
      } else {
        maxFraction = 20
        maxSignificant = 4
      }
    } else {
      maxFraction = maximumFractionDigits
      minFraction = minimumFractionDigits
      maxSignificant = maximumSignificantDigits
    }

    return this.wrapIntlNumberFormat({
      minimumFractionDigits: minFraction,
      maximumFractionDigits: maxFraction,
      maximumSignificantDigits: maxSignificant,
      ...restOptions,
    })
  }
}
